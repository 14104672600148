export default [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/dashboard/index"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/authentication/login"),
    meta: {
      title: 'Login',
      anon:true
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/authentication/register"),
    meta: {
      title: 'Register',
      anon:true
    },
  },
  {
    path: "/confirm",
    name: "Confirm",
    component: () => import("@/views/authentication/confirm"),
    meta: {
      title: 'Confirm',
      anon:true
    },
  },
  {
    path: "/recoverpwd",
    name: "Recover password",
    component: () => import("@/views/authentication/recoverpwd"),
    meta: {
      title: 'Recover Password',
      anon:true
    },
  },{
    path: "/updatepassword",
    name: "UpdatePassword",
    component: () => import("@/views/authentication/updatepassword"),
    params: "username",
    meta: {
      title: 'Update Password',
      anon:true
    },
  },
  {
    path: "/lockscreen",
    name: "Lock Screen",
    component: () => import("@/views/authentication/lockscreen"),
  },
  {
    path: "/devops/ipwhitelists",
    name: "ipwhitelists",
    component: () => import("@/views/devops/ipwhitelists"),
  }, 
  {
    path: "/devops/orphanedactionsv3",
    name: "orphanedactionsv3",
    component: () => import("@/views/devops/orphanedactionsv3"),
  },
  {
    path: "/devops/rdsinstancemanagement",
    name: "rdsinstancemanagement",
    component: () => import("@/views/devops/rdsinstancemanagement"),
  },
  {
    path: "/devops/ec2instancemanagement",
    name: "ec2instancemanagement",
    component: () => import("@/views/devops/ec2instancemanagement"),
  },
  {
    path: "/devops/manualinstancemanagement",
    name: "manualinstancemanagement",
    component: () => import("@/views/devops/manualinstancemanagement"),
  },
  {
    path: "/devops/s3bucketmanagement",
    name: "s3bucketmanagement",
    component: () => import("@/views/devops/s3bucketmanagement"),
  },
  {
    path: "/devops/volumemanagement",
    name: "volumemanagement",
    component: () => import("@/views/devops/volumemanagement"),
  },
  {
    path: "/devops/awsbackups",
    name: "awsbackups",
    component: () => import("@/views/devops/awsbackups"),
  },
  {
    path: "/devops/simpleEmailService",
    name: "simpleEmailService",
    component: () => import("@/views/devops/simple-email-service"),
  },
  {
    path: "/finance/financeAdmin",
    name: "financeAdmin",
    component: () => import("@/views/finance/financeadmin"),
  },
  {
    path: "/devops/v2workflows",
    name: "v2workflows",
    component: () => import("@/views/devops/v2workflows"),
  },  
  {
    path: "/landz/landzemails",
    name: "landzemails",
    component: () => import("@/views/landz/landzemails"),
  },
  {
    path: "/admin/staff",
    name: "staff",
    component: () => import("@/views/admin/staff"),
  },
  {
    path: "/email/inbox",
    name: "Email Inbox",
    component: () => import("@/views/email/inbox/index"),
  },
  {
    path: "/email/read",
    name: "Email read",
    component: () => import("@/views/email/reademail"),
  },
  {
    path: "/email/compose",
    name: "Email compose",
    component: () => import("@/views/email/compose"),
  },
  {
    path: "/changerequests/changerequests",
    name: "changerequests",
    component: () => import("@/views/changerequests/changerequests"),
  },
  {
    path: "/changerequests/changerequest",
    name: "changerequest",
    component: () => import("@/views/changerequests/changerequest"),
  },
  {
    path: "/changerequests/changerequest/:id",
    name: "changerequest",
    component: () => import("@/views/changerequests/changerequest"),
  },
  {
    path: "/paygatesupport/listonsitecustomers",
    name: "listOnsiteCustomers",
    component: () => import("@/views/paygatesupport/listonsitecustomers"),
  },
  {
    path: "/paygatesupport/emailsuppressionlist",
    name: "emailSuppressionList",
    component: () => import("@/views/paygatesupport/awsemailsupressionlist"),
  },
  {
    path: "/paygatesupport/onsitecustomers",
    name: "onsiteCustomers",
    component: () => import("@/views/paygatesupport/onsitecustomers"),
  },
  {
    path: "/paygatesupport/manageonsitecustomer",
    name: "createOnsiteCustomer",
    component: () => import("@/views/paygatesupport/manageonsitecustomer"),
    props: (route) => ({ status: 'Create' })
  },
  {
    path: "/paygatesupport/manageonsitecustomer:/paygateId",
    name: "ManageOnsiteCustomer",
    component: () => import("@/views/paygatesupport/manageonsitecustomer"),
    props: (route) => ({ ...route.params, ...route.query, status: 'Edit' })
  },  
  {
    path: "/engineering/appsecuritytesting",
    name: "AppSecurityTesting",
    component: () => import("@/views/engineering/appsecuritytesting"),
  },
  {
    path: "/engineering/repodeveloper",
    name: "RepoDeveloper",
    component: () => import("@/views/engineering/repodeveloper"),
  },
  {
    path: "/projects",
    name: "Projects",
    component: () => import("@/views/projects/index"),
  },
  {
    path: "/pages/blank",
    name: "Blank Page",
    component: () => import("@/views/extrapages/blank"),
  },
  {
    path: "/pages/maintenance",
    name: "Maintenance",
    component: () => import("@/views/extrapages/maintenance"),
  },
  {
    path: "/pages/timeline",
    name: "Timeline",
    component: () => import("@/views/extrapages/timeline"),
  },
  {
    path: "/pages/faqs",
    name: "FAQs",
    component: () => import("@/views/extrapages/faqs"),
  },
  {
    path: "/pages/pricing",
    name: "Pricing",
    component: () => import("@/views/extrapages/pricing/index"),
  },
  {
    path: "/pages/invoice",
    name: "Invoice",
    component: () => import("@/views/extrapages/invoice"),
  },
  {
    path: "/pages/404",
    name: "Error-404",
    component: () => import("@/views/extrapages/404"),
  },
  {
    path: "/pages/500",
    name: "Error-500",
    component: () => import("@/views/extrapages/500"),
  },
  {
    path: "/ecommerce/products",
    name: "Products",
    component: () => import("@/views/ecommerce/products/index"),
  },
  {
    path: "/ecommerce/products-list",
    name: "Products list",
    component: () => import("@/views/ecommerce/productslist/index"),
  },
  {
    path: "/ecommerce/order-history",
    name: "Order History",
    component: () => import("@/views/ecommerce/order-history/index"),
  },
  {
    path: "/ecommerce/product-edit",
    name: "Product edit",
    component: () => import("@/views/ecommerce/product-edit"),
  },
  {
    path: "/ecommerce/customers",
    name: "Customers",
    component: () => import("@/views/ecommerce/customers/index"),
  },
  {
    path: "/email-template/basic",
    name: "Basic Action Email",
    component: () => import("@/views/email-template/basic"),
  },
  {
    path: "/email-template/alert",
    name: "Alert Email",
    component: () => import("@/views/email-template/alert"),
  },
  {
    path: "/email-template/billing",
    name: "Billing Email",
    component: () => import("@/views/email-template/billing"),
  },
  {
    path: "/ui/alerts",
    name: "Alerts",
    component: () => import("@/views/ui/alerts"),
  },
  {
    path: "/ui/buttons",
    name: "Buttons",
    component: () => import("@/views/ui/buttons"),
  },
  {
    path: "/ui/cards",
    name: "Cards",
    component: () => import("@/views/ui/cards"),
  },
  {
    path: "/ui/carousel",
    name: "Carousel",
    component: () => import("@/views/ui/carousel"),
  },
  {
    path: "/ui/grid",
    name: "Grid",
    component: () => import("@/views/ui/grid"),
  },
  {
    path: "/ui/images",
    name: "Images",
    component: () => import("@/views/ui/images"),
  },
  {
    path: "/ui/modals",
    name: "Modals",
    component: () => import("@/views/ui/modals"),
  },
  {
    path: "/ui/rangeslider",
    name: "Rangeslider",
    component: () => import("@/views/ui/rangeslider"),
  },
  {
    path: "/ui/sweet-alert",
    name: "Sweet-alert",
    component: () => import("@/views/ui/sweet-alert"),
  },
  {
    path: "/ui/tabs-accordions",
    name: "Tabs-accordions",
    component: () => import("@/views/ui/tabs-accordions"),
  },
  {
    path: "/ui/typography",
    name: "Typography",
    component: () => import("@/views/ui/typography"),
  },
  {
    path: "/ui/general",
    name: "General",
    component: () => import("@/views/ui/general"),
  },
  // {
  //   path: "/form/advanced",
  //   name: "Form Advanced",
  //   component: () => import("@/views/forms/advanced"),
  // },
  {
    path: "/form/elements",
    name: "Form Elements",
    component: () => import("@/views/forms/elements"),
  },
  {
    path: "/form/editor",
    name: "CK Editor",
    component: () => import("@/views/forms/ckeditor"),
  },
  {
    path: "/form/uploads",
    name: "File Uploads",
    component: () => import("@/views/forms/uploads"),
  },
  {
    path: "/form/validation",
    name: "Form Validation",
    component: () => import("@/views/forms/validation"),
  },
  {
    path: "/form/wizard",
    name: "Form Wizard",
    component: () => import("@/views/forms/wizard"),
  },
  {
    path: "/form/mask",
    name: "Form Mask",
    component: () => import("@/views/forms/mask"),
  },
  {
    path: "/charts/apex",
    name: "Apex chart",
    component: () => import("@/views/charts/apex/index"),
  },
  {
    path: "/charts/chartjs",
    name: "Chartjs chart",
    component: () => import("@/views/charts/chartjs/index"),
  },
  {
    path: "/charts/echart",
    name: "Echart chart",
    component: () => import("@/views/charts/echart/index"),
  },
  {
    path: "/charts/chartist",
    name: "Chartist chart",
    component: () => import("@/views/charts/chartist/index"),
  },
  {
    path: "/icons/themify",
    name: "themify Icon",
    component: () => import("@/views/icons/themify"),
  },
  {
    path: "/icons/materialdesign",
    name: "Materialdesign Icon",
    component: () => import("@/views/icons/materialdesign"),
  },
  {
    path: "/icons/ion",
    name: "Ion Icon",
    component: () => import("@/views/icons/ion"),
  },
  {
    path: "/icons/dripicons",
    name: "Dripicons Icon",
    component: () => import("@/views/icons/dripicons"),
  },
  {
    path: "/icons/fontawesome",
    name: "Fontawesome Icon",
    component: () => import("@/views/icons/fontawesome"),
  },
  {
    path: "/tables/basictable",
    name: "Basic Tables",
    component: () => import("@/views/tables/basictable"),
  },
  {
    path: "/tables/advancedtable",
    name: "Advanced Tables",
    component: () => import("@/views/tables/advanced/index"),
  },
  {
    path: "/maps/google",
    name: "Google Maps",
    component: () => import("@/views/maps/google"),
  },
];
