import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store/index"
import axios from 'axios'

import BootstrapVue from "bootstrap-vue"
import VueApexCharts from "vue-apexcharts"
import Vuelidate from "vuelidate"
import VueGoodTable from "vue-good-table"
import vco from "v-click-outside"
import VueMask from "v-mask"
import VuejsDatepicker from 'vuejs-datepicker'
// import * as VueGoogleMaps from "vue2-google-maps"

import "@/design/app.scss"
import 'vue-good-table/dist/vue-good-table.css'
import Toastr from 'vue-toastr'
import VueSweetAlert2 from 'vue-sweetalert2'
import VueSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

Vue.config.productionTip = false

Vue.use(VueGoodTable)
Vue.use(Vuelidate)
Vue.use(vco)
Vue.use(VueMask)

Vue.use(BootstrapVue)
Vue.use(require("vue-chartist"))
Vue.use(VuejsDatepicker)
Vue.use(Toastr)
Vue.use(VueSweetAlert2)
Vue.use(VueSelect)

Vue.component("apexchart", VueApexCharts)
Vue.component('vuejsDatepicker', VuejsDatepicker)

// Setup Axios
// axios.defaults.headers.common['X-Paygate-Embedded'] = `${embeddedId}${date.getUTCDay()}${date.getUTCMonth()}${date.getUTCFullYear()}`
//axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
//axios.defaults.headers.get.Accepts = 'application/json'
axios.defaults.headers.post['Content-Type'] = 'application/json charset=utf-8'
axios.defaults.headers.put['Content-Type'] = 'application/json charset=utf-8'
axios.defaults.headers.delete['Content-Type'] = 'application/json charset=utf-8'
// for IE11
//axios.defaults.headers.Pragma = 'no-cache'

axios.interceptors.response.use(async function (response) {
    return response
}, function (error) {
    try {
      console.log('Error: ', error)
      console.log(error.response.data)
      if (error.response.status === 401) {
        store.dispatch('logout')
        router.push('/login')
      } 
    } catch {
        console.log('Failed to process error in axios interceptor')
    }
    return Promise.reject(error)
})


// Make vuex store in localstorage
store.subscribe((mutation, state) => {
    // Store the state object as a JSON string
    localStorage.setItem('store', JSON.stringify(state))
})

store.commit('initialiseStore')

export const vueInstance = new Vue({
    router,
    store,
    components: {
        'vue-toastr': Toastr
    },
    render: (h) => h(App),
    async beforeMount() {
        // toastr global default settings
        this.$toastr.defaultPosition = 'toast-bottom-right'
        this.$toastr.defaultTimeout = 5000

    }
}).$mount("#app")
