/*
  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
  SPDX-License-Identifier: MIT-0 
*/
export default {
  setCognitoUser(context, payload) {
    context.commit("setCognitoUser", payload);
  },
  logout(context) {
    console.log("log out called");
    context.commit("setCognitoUser", {})
    localStorage.clear();
  }
}
